import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Polis Knowledge Base`}</h1>
    <p>{`The Computational Democracy Project maintains an extensive (and growing) knowledge base for Polis and related facilitation methods.`}</p>
    <p><a parentName="p" {...{
        "href": "/Welcome"
      }}>{`👋Welcome Guide`}</a></p>
    <p><a parentName="p" {...{
        "href": "/Quickstart"
      }}>{`🏎Quickstart`}</a></p>
    <p><a parentName="p" {...{
        "href": "/Usage"
      }}>{`🔩Usage Overview`}</a></p>
    <p><a parentName="p" {...{
        "href": "/FAQ"
      }}>{`📖FAQ`}</a></p>
    <p><a parentName="p" {...{
        "href": "/Case-studies"
      }}>{`⚗️ Case Studies`}</a></p>
    <p><a parentName="p" {...{
        "href": "/Algorithms"
      }}>{`👾Algorithms`}</a></p>
    <p><a parentName="p" {...{
        "href": "/Moderation"
      }}>{`👹Best Practices for Moderation`}</a></p>
    <p><a parentName="p" {...{
        "href": "/Media-coverage"
      }}>{`🗞 Press`}</a></p>
    <p>{`The goals of the Polis `}<a parentName="p" {...{
        "href": "/knowledge-base",
        "title": "knowledge base"
      }}>{`knowledge base`}</a>{` are:`}</p>
    <ul>
      <li parentName="ul">{`To keep a living record of case studies and discussion of `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` as a technology`}</li>
      <li parentName="ul">{`To keep a living documentation of the system, its capabilities, and its methods`}</li>
      <li parentName="ul">{`To preserve and disseminate best practices`}</li>
      <li parentName="ul">{`To make it easier for early adopters in an organization to communicate the tool and processes`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      